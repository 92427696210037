<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link>
                    &gt; <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="teal darken-2" dark>
                            <v-toolbar-title>Create new topic</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">
                            <p>
                                A topic is a category of communications to which a user can opt-in or opt-out.
                                This makes it possible for a user to tailor the communication they receive
                                from you, instead of opting out of everything.
                                You can change the "label" at any time, and all contacts to which the topic
                                was already applied will be automatically updated. For this reason,
                                you should only change it to something with a similar meaning -- if a user
                                opts in to "Product announcements" and you change the topic to "Dinosaur pictures"
                                the users will not be happy to start receiving dionsaur pictures from you instead
                                of product announcements.
                            </p>

                            <!-- <v-select outlined dense :items="newTopicTypeChoices" v-model="newTopicType" color="teal darken-2" label="Type" required hint="The kind of data to which this topic will be applied">
                            </v-select> -->

                            <v-text-field
                                ref="topicLabelInput"
                                v-model="newTopicLabel"
                                label="Label"
                                :rules="newTopicLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <p class="text-overline mb-0 mt-2">
                                Visibility
                                <v-btn icon @click="displayVisibilityHelp = !displayVisibilityHelp" color="teal darken-2">
                                    <font-awesome-icon :icon="['fas', 'question-circle']"/>
                                </v-btn>
                            </p>
                            <template v-if="displayVisibilityHelp">
                                <p class="mb-0 pb-0">
                                    Should this topic be visible in your subscribe page for people to opt-in themselves?
                                </p>
                                <p class="mb-0 pb-0 mt-2">
                                    Usually this should be allowed. You might want to uncheck this box if you have a special topics that are used only for some contacts and should not be visible to all contacts, and you have a process for automatically or interactively adding such a topic for a contact. If a topic is hidden and a contact unsubscribes, they won't be able to subscribe to it again by themselves, they will need to be added by you.
                                </p>
                                <p class="mb-0 pb-0 mt-2">
                                    When a contact is subscribed to topic, this setting does not apply because it must appear in their email preferences page to allow opt-out.
                                </p>
                            </template>
                            <p class="mb-0 pb-0">
                                <v-checkbox v-model="newAllowInbound" label="Show topic in the subscribe page" class="mt-0"></v-checkbox>
                            </p>

                            <p>
                                A comment is optional. You can use it to remember how you are using the topic. You can change the comment at any time. The comment is private and will NOT be shown to users.
                            </p>

                            <v-textarea
                                ref="topicCommentInput"
                                v-model="newTopicComment"
                                label="Comment"
                                hint="Comment is optional"
                                :rules="newTopicCommentRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                            >
                            </v-textarea>

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="teal darken-2 white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        typeList: [],

        error: null,
        website: null,
        // create form
        createTopicForm: null,
        submitFormTimestamp: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newTopicLabel: null,
        newTopicLabelRules: [
            (v) => !!v || 'Label is required',
        ],
        // newTopicType: null,
        // newTopicTypeChoices: [], // will be loaded from server
        newTopicComment: null,
        newTopicCommentRules: [
            // (v) => !!v || 'comment is required',
        ],

        displayVisibilityHelp: false,
        newAllowInbound: true,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newTopicLabel === 'string' && this.newTopicLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newTopicLabel === null || this.newTopicLabel.length === 0) {
        //                 this.newTopicLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            this.createTopic();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createTopic() {
            try {
                this.error = false;
                this.$store.commit('loading', { createTopic: true });
                console.log('createTopic');
                const request = {
                    label: this.newTopicLabel,
                    allow_inbound: this.newAllowInbound,
                    // type: this.newTopicType,
                    comment: this.newTopicComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).topic.create(request);
                console.log('createTopic response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-topic', params: { accountId: this.$route.params.accountId, topicId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create topic' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create topic' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create topic', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create topic' });
            } finally {
                this.$store.commit('loading', { createTopic: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadTypeList();
        this.$nextTick(() => {
            setTimeout(() => { this.activate('topicLabelInput'); }, 1);
        });
    },
};
</script>
